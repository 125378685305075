import React from 'react'
import Components from './components.js';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import { LinkTreeLink } from './linktree_link.js';
import Styles from "./linktree.module.css"

class LinkTree extends React.Component {                                                                             

  render() {
	  console.log("linktreehello");
	  console.log(this.props.blok);
          this.props.blok.links.map((link) => console.log(link));
          const linkItems = this.props.blok.links.map((l) =>  <Link to={l.link.cached_url} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" target="_blank"> {l.title}</Link> );
    return(
  <div>
    <Helmet>
       <title>{this.props.blok.head[0].title}</title>
    </Helmet>
     <div className="container mb-3 mt-3">
         <div className="row justify-content-md-center">
             <div className="col-12 col-md-8 col-lg-4">
                <img className="img-fluid" src={this.props.blok.top_image.filename} alt={this.props.blok.top_image.alt}/>
                <h1 className={`h2 ${Styles.linktreeH1} text-center`}>{this.props.blok.title}</h1>
                <h2 className="h4 text-center">{this.props.blok.subtitle}</h2>
	        <div className="list-group list-group-flush">
                   {linkItems}
	        </div>
            </div>
         </div>
     </div>
  </div>
    
    );
  }

}


export default LinkTree
