import React from 'react'
import { Link } from "gatsby"
const ReactMarkdown = require('react-markdown');

class LinkTreeLink extends React.Component {
  
  	render() {
      return (
            <div className="container">
				<div className="row">
		    		<div className="col-12 pl-0 pb-5">
		      			<h2><Link to={this.props.url}>{this.props.title}</Link></h2>
		      			<p><strong>{this.props.subtitle}</strong></p>
		    		</div>
				</div>
			</div>
      )
    }
}

export default LinkTreeLink
